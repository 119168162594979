<template>
  <div class="map-results__body-table">
    <div class="table-item" v-for="(item, i) in results" :key="`item-${i}`">
      <div class="table-item__text">{{ item.text }}</div>
      <div class="table-item__val">{{ item.val }} {{ item.unit }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    results: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  name: 'ResultsTable'
}
</script>

<style scoped lang="sass">
.map-results
  &__body
    &-table
      display: grid
      +media((grid-template-columns: (320: 1fr, 768: 1fr 1fr)))
      +media((column-gap: (768: rem(24), 1024: rem(48), 1600: rem(68))))
      color: $black_A
      .table-item
        display: flex
        align-items: center
        justify-content: space-between
        +media((padding: (320: rem(12) 0, 768: rem(16) 0)))
        &:not(:last-child)
          +media((box-shadow: (320: inset 0 rem(-1) 0 $cool_B, 768: none)))
        &:not(:nth-last-child(-n + 2))
          +media((box-shadow: (768: inset 0 rem(-1) 0 $cool_B)))

        // &:nth-last-child(-n + 2)
          padding: rem(16) 0 0
        // &:nth-child(-n + 2)
          padding: 0 0 rem(16)
        // &:nth-last-child(-n + 2):nth-child(-n + 2)
          background: red
        &__text, &__val
          @extend %14
        &__text
          +media((padding-right: (320: rem(20), 768: rem(32), 1200: rem(48))))
        &__val
          +media((min-width: (320: rem(80), 1200: rem(96))))
          font-weight: bold
</style>
